import React from "react"
import Header from "../header/Index"
import Footer from "../footer/Index"
import { Helmet } from "react-helmet"
import { withTrans } from "../../i18n/withTrans"
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#00a650",
    },
  },
})

const Index = ({ children, t, i18n }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GAKESLAB</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <Header t={t} i18n={i18n} />
      <ThemeProvider theme={theme}>
        <div className="main-content">{children}</div>
      </ThemeProvider>
      <Footer t={t} i18n={i18n} />
    </>
  )
}

export default withTrans(Index)
