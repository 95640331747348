import React from "react"

const Index = ({ srcIcon, link, onClick, language, lang }) => {
  return (
    <a
      href={link}
      className={`language ${language === lang ? "active" : ""}`}
      onClick={onClick}
    >
      <img src={srcIcon} alt="lang" />
    </a>
  )
}

export default Index
