import React from "react"

const Link = ({ links, t }) => (
  <div className="footer-link footer-align-right mini-left">
    <h3>{t("footer.link")}</h3>
    <ul>
      {links.map((item, index) => (
        <li key={index}>
          <a href={item.link}>{item.label}</a>
        </li>
      ))}
    </ul>
  </div>
)

export default Link
