import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const About = ({ desc, t }) => (
  <div className="upper-footer-about mini-left">
    <div className="gakeslab-logo">
      <StaticImage
        src="../../../../static/images/ic_logo_gakeslab.png"
        alt="gakeslab"
        width={56}
        height={56}
        placeholder="blurred"
      />
      <div className="logo-label">
        <span>GAKESLAB INDONESIA</span>
        <p>{t("footer.aboutLogo")}</p>
      </div>
    </div>
    <h3>{t("footer.aboutGakeslab")}</h3>
    <p className="about-desc">{desc}</p>
  </div>
)

export default About
