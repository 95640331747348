import React from "react"

const Index = ({ srcIcon, link }) => (
  <div className="socmed-link">
    <a href={link} target="_blank" rel="noreferrer">
      <img src={srcIcon} alt="socmed-icon" />
    </a>
  </div>
)

export default Index
