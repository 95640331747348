import React from "react"
import { Breadcrumb, Container } from "react-bootstrap"
import TopNavbar from "./TopNavbar"
import Navbar from "./Navbar"
import { Link } from "gatsby"

const Index = ({ t, i18n }) => {
  const path = typeof window !== "undefined" ? window.location.pathname : ""
  const split_path = path.split("/")
  const lengthPath = split_path.length

  let breadlink

  switch (path) {
    case "/tentang-kami/sejarah":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: path }}
          >
            {t("header.aboutUs")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.history")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/tentang-kami/visi-misi":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/tentang-kami/sejarah" }}
          >
            {t("header.aboutUs")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.visionMision")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/keanggotaan/pengurus":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: path }}
          >
            {t("header.membership")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.management")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/keanggotaan/anggota-aktif":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/keanggotaan/pengurus" }}
          >
            {t("header.membership")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.activeMember")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/berita":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: path }}
          >
            {t("header.news")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.news")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/berita/artikel":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/berita" }}
          >
            {t("header.news")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.article")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/agenda":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: path }}
          >
            {t("header.activities")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.agenda")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/kegiatan/galeri":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/agenda" }}
          >
            {t("header.activities")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.gallery")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/regulasi/tugas-pengurus-pusat":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: path }}
          >
            {t("header.regulation")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.centralManagementDuties")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/regulasi/kumpulan-peraturan":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/regulasi/tugas-pengurus-pusat" }}
          >
            {t("header.regulation")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.setOfRules")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/regulasi/peraturan-bidang-alat-kesehatan":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/regulasi/tugas-pengurus-pusat" }}
          >
            {t("header.regulation")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.regulationMedicalDevices")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/regulasi/circular-internal":
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/regulasi/tugas-pengurus-pusat" }}
          >
            {t("header.regulation")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            {t("header.circularInternal")}
          </Breadcrumb.Item>
        </>
      )
      break
    case "/technopark":
      breadlink = (
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
          {t("header.technopark")}
        </Breadcrumb.Item>
      )
      break
    case "/hubungi-kami":
      breadlink = (
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
          {t("header.contactUs")}
        </Breadcrumb.Item>
      )
      break
    case "/registrasi":
      breadlink = (
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
          {t("header.registration")}
        </Breadcrumb.Item>
      )
      break
    default:
  }

  if (split_path.includes("pengurus")) {
    if (lengthPath > 3) {
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/keanggotaan/pengurus" }}
          >
            {t("header.membership")}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/keanggotaan/pengurus" }}
          >
            {t("header.management")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            Detail {t("header.management")}
          </Breadcrumb.Item>
        </>
      )
    }
  } else if (split_path.includes("anggota-aktif")) {
    if (lengthPath > 3) {
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/keanggotaan/pengurus" }}
          >
            {t("header.membership")}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/keanggotaan/anggota-aktif" }}
          >
            {t("header.activeMember")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            Detail {t("header.activeMember")}
          </Breadcrumb.Item>
        </>
      )
    }
  } else if (split_path[2] === "artikel") {
    if (lengthPath > 3) {
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/berita" }}
          >
            {t("header.news")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/berita/artikel" }}>
            {t("header.article")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            Detail {t("header.article")}
          </Breadcrumb.Item>
        </>
      )
    }
  } else if (split_path[1] === "berita") {
    if (lengthPath > 2) {
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/berita" }}
          >
            {t("header.news")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/berita" }}>
            {t("header.news")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            Detail {t("header.news")}
          </Breadcrumb.Item>
        </>
      )
    }
  } else if (split_path.includes("agenda")) {
    if (lengthPath > 2) {
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/agenda" }}
          >
            {t("header.activities")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/agenda" }}>
            {t("header.agenda")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            Detail {t("header.agenda")}
          </Breadcrumb.Item>
        </>
      )
    }
  } else if (split_path.includes("galeri")) {
    if (lengthPath > 3) {
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/agenda" }}
          >
            {t("header.activities")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/kegiatan/galeri" }}>
            {t("header.gallery")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            Detail {t("header.gallery")}
          </Breadcrumb.Item>
        </>
      )
    }
  } else if (split_path.includes("kumpulan-peraturan")) {
    if (lengthPath > 3) {
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/regulasi/tugas-pengurus-pusat" }}
          >
            {t("header.regulation")}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/regulasi/kumpulan-peraturan" }}
          >
            {t("header.setOfRules")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            Detail {t("header.setOfRules")}
          </Breadcrumb.Item>
        </>
      )
    }
  } else if (split_path.includes("peraturan-bidang-alat-kesehatan")) {
    if (lengthPath > 3) {
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/regulasi/tugas-pengurus-pusat" }}
          >
            {t("header.regulation")}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/regulasi/peraturan-bidang-alat-kesehatan" }}
          >
            {t("header.regulationMedicalDevices")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            Detail {t("header.regulationMedicalDevices")}
          </Breadcrumb.Item>
        </>
      )
    }
  } else if (split_path.includes("circular-internal")) {
    if (lengthPath > 3) {
      breadlink = (
        <>
          <Breadcrumb.Item
          // linkAs={Link}
          // linkProps={{ to: "/regulasi/tugas-pengurus-pusat" }}
          >
            {t("header.regulation")}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/regulasi/circular-internal" }}
          >
            {t("header.circularInternal")}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: path }} active>
            Detail {t("header.circularInternal")}
          </Breadcrumb.Item>
        </>
      )
    }
  }

  return (
    <>
      <TopNavbar />
      <Navbar t={t} i18n={i18n} />
      <Container className="breadcrumb-a">
        {breadlink && <img src="../../images/ic_beranda.png" alt="GAKESLAB" />}
        <Breadcrumb>
          {/* <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
            {t("header.home")}
          </Breadcrumb.Item> */}
          {breadlink}
        </Breadcrumb>
      </Container>
    </>
  )
}

export default Index
