import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import Dropdown from "./Dropdown"
import { Container, Row, Col, Button } from "react-bootstrap"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const Index = ({ path, label, childMenu, active }) => {
  const [show, setShow] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setIsMobile(window.innerWidth <= 820 ? true : false)
    }
    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return (
    <li className="parent-menu">
      {!childMenu ? (
        <Link to={path ? path : "#"} className={active && "active"}>
          {label}
        </Link>
      ) : (
        <>
          {isMobile ? (
            <>
              <Row onClick={() => setShow(!show)}>
                <Col xs={10}>
                  <a>{label}</a>
                </Col>
                <Col xs={2}>
                  {show ? <NavigateNextIcon /> : <ExpandMoreIcon />}
                </Col>
              </Row>
              {show && (
                <ul className="submenu-container">
                  {childMenu.map(item => (
                    <li className="submenu-item">
                      <Link to={item.path}>{item.label}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          ) : (
            <>
              <Link className={active && "active"}>{label}</Link>
              <Dropdown childMenu={childMenu} />
            </>
          )}
        </>
      )}
    </li>
  )
}

export default Index
