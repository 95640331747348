import { Link } from "gatsby"
import React from "react"

const Dropdown = ({ childMenu }) => (
  <div className="navbar-dropdown">
    <ul>
      {childMenu.map((item, index) => (
        <li key={index}>
          <Link to={item.path}>{item.label}</Link>
        </li>
      ))}
    </ul>
  </div>
)

export default Dropdown
