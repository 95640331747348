import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Socmed from "../commons/socmed/Index"
import Upper from "./upper/Index"

const Index = ({t}) => (
  <div className="footer">
    <div className="upper-footer">
      <Upper t={t} />
    </div>
    <div className="bottom-footer">
      <Container>
        <Row className="mini-footer-label">
          <Col className="footer-label">
            &copy; Copyright 2021 | Website by BIGIO
          </Col>
          <Col className="footer-socmed">
            <Socmed
              srcIcon="../../../images/ic_fb_circle.png"
              link="https://m.facebook.com/Gakeslab-Indonesia-110256061258667/?__tn__=%7E%7E-R"
            />
            <Socmed
              srcIcon="../../../images/ic_youtube_circle.png"
              link="http://youtube.com"
            />
            <Socmed
              srcIcon="../../../images/ic_ig_circle.png"
              link="https://www.instagram.com/gakeslab.id/?hl=en"             
            />
            <Socmed
              srcIcon="../../../images/ic_twitter_circle.png"
              link="https://twitter.com/gakeslab?lang=en"
            />
          </Col>
        </Row>
      </Container>
    </div>
  </div>
)

export default Index
