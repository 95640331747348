import React, { useState, useEffect } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import NavbarMenu from "../layout/navbar/Index"
import Language from "../commons/language/Index"
import { navigate } from "gatsby"
import { withTrans } from "../../i18n/withTrans"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

import MenuIcon from "@material-ui/icons/Menu"

const Navbar = ({ t }) => {
  const [show, setShow] = useState(false)
  const [navActive, setNavActive] = useState("home")
  const [isMobile, setIsMobile] = useState(false)
  const [fullPath, setFullPath] = useState(["", ""])
  const path = fullPath[1]

  const { i18n } = useTranslation()

  function handleChange(value) {
    i18n.changeLanguage(value)
  }

  useEffect(() => {
    setFullPath(window.location.pathname.split("/"))
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setIsMobile(window.innerWidth <= 820 ? true : false)
    }
    // Add event listener
    window.addEventListener("resize", handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return (
    <Container className="bottom-navbar">
      <Row className="mini-navbar">
        <Col md={2} xl={3} className="left-section row-navbar">
          <div className="d-flex align-items-center">
            <div>
              <StaticImage
                src="../../../static/images/header_logo.png"
                alt="header-logo"
                placeholder="blurred"
                height={80}
                width={100}
              />
            </div>
            <Col>
              <div>
                <span style={{ fontWeight: 700, fontSize: 19 }}>GAKESLAB</span>
              </div>
              <div>
                <span>INDONESIA</span>
              </div>
            </Col>
          </div>
        </Col>
        <Col md={10} xl={9} className="right-section row-navbar">
          <div className="mini-show">
            <Button onClick={() => setShow(!show)} style={{ marginRight: 16 }}>
              <MenuIcon />
            </Button>
          </div>
          <Row
            className={
              isMobile
                ? show
                  ? "navbar-mobile show"
                  : "navbar-mobile hide"
                : ""
            }
          >
            <Col md={10} className="navbar-list">
              <ul>
                <NavbarMenu
                  path="/"
                  label={t("header.home")}
                  active={path === ""}
                />
                <NavbarMenu
                  label={t("header.aboutUs")}
                  active={path === "tentang-kami"}
                  childMenu={[
                    {
                      path: "/tentang-kami/sejarah",
                      label: t("header.history"),
                    },
                    {
                      path: "/tentang-kami/visi-misi",
                      label: t("header.visionMision"),
                    },
                  ]}
                />
                <NavbarMenu
                  label={t("header.membership")}
                  active={path === "keanggotaan"}
                  childMenu={[
                    {
                      path: "/keanggotaan/pengurus",
                      label: t("header.management"),
                    },
                    {
                      path: "/keanggotaan/anggota-aktif",
                      label: t("header.activeMember"),
                    },
                  ]}
                />
                <NavbarMenu
                  label={t("header.news")}
                  active={path === "berita"}
                  childMenu={[
                    {
                      path: "/berita",
                      label: t("header.news"),
                    },
                    {
                      path: "/berita/artikel",
                      label: t("header.article"),
                    },
                  ]}
                />
                <NavbarMenu
                  label={t("header.activities")}
                  active={path === "agenda" || path === "kegiatan"}
                  childMenu={[
                    {
                      path: "/agenda",
                      label: t("header.agenda"),
                    },
                    {
                      path: "/kegiatan/galeri",
                      label: t("header.gallery"),
                    },
                  ]}
                />
                <NavbarMenu
                  label={t("header.regulation")}
                  active={path === "regulasi"}
                  childMenu={[
                    {
                      path: "/regulasi/tugas-pengurus-pusat",
                      label: t("header.centralManagementDuties"),
                    },
                    {
                      path: "/regulasi/kumpulan-peraturan",
                      label: t("header.setOfRules"),
                    },
                    {
                      path: "/regulasi/peraturan-bidang-alat-kesehatan",
                      label: t("header.regulationMedicalDevices"),
                    },
                    {
                      path: "/regulasi/circular-internal",
                      label: t("header.circularInternal"),
                    },
                  ]}
                />
                <NavbarMenu
                  path="/technopark"
                  label={t("header.technopark")}
                  active={path === "technopark"}
                />
                <NavbarMenu
                  path="/hubungi-kami"
                  label={t("header.contactUs")}
                  active={path === "hubungi-kami"}
                />
              </ul>
            </Col>
            {show && (
              <Row className="parent-menu">
                <Col xs={6}>
                  <label style={{ fontWeight: 700, paddingLeft: "20px" }}>
                    Pilih Bahasa
                  </label>
                </Col>
                <Col xs={6}>
                  <Language
                    srcIcon="../../../images/ic_indonesia.png"
                    link="#"
                    onClick={() => handleChange("id")}
                    language={i18n}
                    lang="id"
                  />
                  <Language
                    srcIcon="../../../images/ic_inggris.png"
                    link="#"
                    onClick={() => handleChange("en")}
                    language={i18n}
                    lang="en"
                  />
                </Col>
              </Row>
            )}
            <Col md={2} className="button-container">
              <Button onClick={() => navigate("/registrasi")}>
                {t("header.registration")}
              </Button>
            </Col>
            {show && (
              <>
                <Col md={3} className="button-container ">
                  <Button
                    onClick={() => navigate("/registrasi")}
                    className="btn-outline-primary"
                  >
                    LOGIN
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default withTrans(Navbar)
