import React from "react"

const Index = ({ srcIcon, label }) => (
  <div className="contact">
    <img src={srcIcon} alt="" />
    <label>{label}</label>
  </div>
)

export default Index
