import React from "react"
import About from "./About"
import Link from "./Link"
import ContactUs from "./ContactUs"
import { Col, Container, Row } from "react-bootstrap"

const Index = ({ t }) => (
  <Container>
    <Row className="mini-left">
      <Col md={6}>
        <About desc={t("footer.aboutMessage")} t={t} />
      </Col>
      <Col md={{ span: 2, offset: 1 }}>
        <Link
          t={t}
          links={[
            {
              link: "/keanggotaan/anggota-aktif",
              label: t("footer.membership"),
            },
            {
              link: "/agenda",
              label: "Event",
            },
            {
              link: "/regulasi/tugas-pengurus-pusat",
              label: t("footer.regulation"),
            },
            {
              link: "/tentang-kami/sejarah",
              label: t("footer.aboutGakeslab"),
            },
            {
              link: "/registrasi",
              label: t("footer.registration"),
            },
            {
              link: "/registrasi",
              label: "Member Benefit",
            },
          ]}
        />
      </Col>
      <Col md={{ span: 2, offset: 1 }} className="footer-contact-section">
        <ContactUs
          t={t}
          address="Ruko Graha Mas Pemuda, Blok AD, No 23, Lantai 1, Jl Pemuda Raya, Jakarta Timur, 13220"
          email="admin@gakeslabindonesia.id"
          phone="+62 21 2248 0259"
        />
      </Col>
    </Row>
  </Container>
)

export default Index
