import React from "react"
import Contact from "../commons/contact/Index"
import { useState } from "react"
import Language from "../commons/language/Index"
import { Container, Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const TopNavbar = () => {
  const { t, i18n } = useTranslation()
  const localStorageKey = "language"

  function handleChange(value) {
    if (typeof Storage !== "undefined") {
      localStorage.setItem(localStorageKey, value)
    }
    i18n.changeLanguage(value)
  }

  // console.log(process.env.GATSBY_ADMIN_URL)
  return (
    <div className="top-navbar">
      <Container>
        <Row>
          <Col md={6} className="left-section">
            <Row>
              <Contact
                srcIcon="../../../images/ic_phone_green.png"
                label="+62 21 2248 0259"
              />
              <Contact
                srcIcon="../../../images//ic_mail_green.png"
                label="admin@gakeslabindonesia.id"
              />
            </Row>
          </Col>
          <Col
            md={{ span: 3, offset: 3 }}
            xl={{ span: 2, offset: 4 }}
            className="right-section"
          >
            <Row>
              <Col md={{ span: 6, offset: 2 }} className="language-section">
                <Language
                  srcIcon="../../../images/ic_indonesia.png"
                  link="#"
                  onClick={() => handleChange("id")}
                  language={i18n.language}
                  lang="id"
                />
                <Language
                  srcIcon="../../../images/ic_inggris.png"
                  link="#"
                  onClick={() => handleChange("en")}
                  language={i18n.language}
                  lang="en"
                />
              </Col>
              <Col md={4} className="login-section">
                <a href={`${process.env.GATSBY_ADMIN_URL}/login`}>Login</a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TopNavbar
