import i18next from "i18next"

const localStorageKey = "language"
let bahasa

if (typeof Storage !== "undefined") {
  if (localStorage.getItem(localStorageKey) === null) {
    localStorage.setItem(localStorageKey, "id")
  }

  bahasa = localStorage.getItem(localStorageKey)
}

i18next.init({
  fallbackLng: bahasa,
  resources: {
    id: {
      translations: require("../locales/id/translations.json"),
    },
    en: {
      translations: require("../locales/en/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
})

i18next.languages = ["id", "en"]

export default i18next
