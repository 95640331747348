import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const ContactUs = ({ address, email, phone, t }) => {
  return (
    <Container>
      <Row>
        <Col>
          <div className="footer-contact footer-align-right mini-left">
            <h3>{t('footer.contactUs')}</h3>
            <div className="contact-address-section">
              <p>{address}</p>
            </div>
            <div className="contact-email-phone-section">
              <p>{email}</p>
              <p>{phone}</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default ContactUs
